<template>
  <div id="route-info" class="p-2">
    <div id="avatar" class="d-flex justify-content-center mt-2 mb-3 text-primary"><fa-icon :icon="['fad','route']" size="6x"/></div>
    <div id="route-info" class="h4">
      <template v-if="update"><!-- 更新和添加 -->
        <q-form @submit.prevent="updateRoute()" class="d-flex flex-column gap-3">
          <!-- name -->
          <q-input type="text" ref="nameRef" v-model="routeData.name" class="pb-0" filled :label="$t('user.name')" :loading="loading" autocomplete="off" clearable lazy-rules filled
            :rules="[ val=>val !== null && val !== undefined && val !== '' || $t('rule.required'), val=>val.length<=50 || 'Name is too long']">
            <template #prepend><fa-icon :icon="['fad','route']" fixed-width class="text-primary"/></template>
          </q-input>
          <!-- site -->
          <!-- <q-input type="text" ref="numberRef" v-model="routeData.site" class="pb-0" filled label :label="$t('security.site')" :loading="loading" clearable autocomplete="off" 
            :rules="[ val=>val !== null && val !== undefined && val !== '' || $t('rule.required'), val=>val.length<=50 || 'Name is too long']">
            <template #prepend><fa-icon :icon="['fad','map-marker-alt']" fixed-width class="text-primary"/></template>
          </q-input> -->
          <!-- tags -->
          <q-select v-model="routeData.tags" outlined :label="$t('tags')" new-value-mode="add-unique" multiple hide-dropdown-icon use-input >
            <template #selected-item="scope">
              <q-chip dense removable @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex" color="light-primary" text-color="primary">{{scope.opt}}</q-chip>
            </template>
          </q-select>
          <!-- Button -->
          <div class="d-flex justify-content-between mt-2">
            <q-btn v-if="update" push color="primary" no-caps type="submit" class="text-capitalize rounded-lg">{{routeId?$t('update'):$t('action.add')}}</q-btn>
            <q-btn color="danger" push class="text-capitalize rounded-lg" @click.prevent.stop="$store.commit('route/TOGGLE_SIDEBAR', false)">{{$t('cancel')}}</q-btn>
          </div>
        </q-form>
      </template>

      <template v-else><!-- 详情 -->
        <div class="d-flex flex-column gap-3">
          <!-- Name -->
          <div v-if="routeData.name" class="d-flex gap-2">
            <fa-icon :icon="['fad','route']" fixed-width class="text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block">{{$t('user.name')}}</label>
              {{routeData.name}}
            </div>
          </div>
          <!-- Site -->
          <div v-if="routeData.site" class="d-flex gap-2">
            <fa-icon :icon="['fad','map-marker-alt']" fixed-width class="text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block">{{$t('security.site')}}</label>
              {{routeData.site}}
            </div>
          </div>
          <!-- Points -->
          <div v-if="routeData.points && routeData.points.length>0" class="d-flex gap-2">
            <fa-icon :icon="['fad','dot-circle']" fixed-width class="text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block">{{$t('Points')}}</label>
              <q-chip dense v-for="(point,i) in routeData.points" :key="i" color="primary" text-color="white" class="mr-50 px-50">{{point}}</q-chip>
            </div>
          </div>
          <!-- Tags -->
          <div v-if="routeData.tags && routeData.tags.length>0" class="d-flex gap-2">
            <fa-icon :icon="['fad','tag']" fixed-width class="text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block">{{$t('tags')}}</label>
              <q-chip dense v-for="(tag,i) in routeData.tags" :key="i" color="primary" text-color="white" class="mr-50 px-50">{{tag}}</q-chip>
            </div>
          </div>
        </div>        
      </template>
    </div>
    
  </div>
</template>

<script>
import useRoute from '../useRoute'
import { BAvatar } from 'bootstrap-vue'

import FileUpload from '@core/components/file-upload/FileUpload'
import FileGallery from '@core/components/file-gallery/FileGallery'
import 'vuesax/dist/vuesax.css'
import { fileUrl } from '@core/utils/filter'

export default {
  name: 'route-info',
  props: {
    update:{
      type: Boolean,
      default: false
    },
  },
  components:{
    BAvatar,
    FileUpload, FileGallery
  },
  setup(props, {emit}){
    const { 
      loading,
      nameRef, numberRef,

      // Sidebar
      routeId,
      routeData,
      routeFiles,
      updateRoute,

      // File
      successUpload,
      failUpload,
      deleteUpload
    } = useRoute(props, emit)

    return {
      loading,
      fileUrl,
      nameRef, numberRef,

      // Sidebar
      routeId,
      routeData,
      routeFiles,
      updateRoute,

      // File
      successUpload,
      failUpload,
      deleteUpload
    }
  }
}
</script>

<style lang="scss">
.q-select {
  div.q-field__native{
    column-gap: 0.5rem;
  }
}
.text-size-150 {
  font-size: 1.5rem
}
</style>
