import axios from '@axios'

export default {
  namespaced: true,
  state: {
    routeListToUpdate: false,
    isSidebarActive: false,
    routeId: '',
    routeData: {files:[]},
    routeFiles: []
  },
  getters: {},
  mutations: {
    SET_IF_ROUTE_LIST_UPDTAE(state, val){
      state.routeListToUpdate = val
    },
    TOGGLE_SIDEBAR(state, val){ // Open/close
      state.isSidebarActive = val
    },
    RESET_ROUTE(state, val){
      state.routeId = '',
      state.routeData = {files:[]},
      state.routeFiles = []
    },
    SET_ROUTE_ID(state, val){
      state.routeId = val
    },
    SET_ROUTE_DATA(state, data){
      state.routeData = data
      state.routeFiles = data.files
    }
  },
  actions: {
    fetchRoutes({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/route', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('SET_IF_ROUTE_LIST_UPDTAE', false)
          })
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchRoute({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/route/${id}`)
          .then(response => {
            commit('SET_ROUTE_DATA', response.data.route)
            resolve(response.data)
          }).catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateRoute(ctx, {id, route}) {
      return new Promise((resolve, reject) => {
        axios.post(`/route/${id||''}`, { ...route })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    disableRoute(ctx, id){
      return new Promise((resolve, reject) => {
        axios.delete(`/route/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeFile(ctx, {id, fileName}){
      let url =''
      if (id) { url = `/route/${id}/file/${fileName.replaceAll('/','%2F')}` } else { url = `/route/file/${fileName}` }
      return new Promise((resolve, reject) => {
        axios.delete(url)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
  },
}
