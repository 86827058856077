import { render, staticRenderFns } from "./RouteList.vue?vue&type=template&id=45c27625&scoped=true&"
import script from "./RouteList.vue?vue&type=script&lang=js&"
export * from "./RouteList.vue?vue&type=script&lang=js&"
import style0 from "./RouteList.vue?vue&type=style&index=0&id=45c27625&lang=scss&scoped=true&"
import style1 from "./RouteList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45c27625",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QChip});
